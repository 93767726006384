import React from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./style.module.css";

export const Games = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 669 });

  return (
    <div className={styles.container} id="games">
      <div className={styles.contentContainer}>
        <div className={styles.gameDescriptionContainer}>
          <p className={styles.title}>Code Name:</p>
          <p className={styles.subTitle}>Rocketman</p>
          <p className={styles.description}>
            Our first ambitious project.A Sci-Fi parody shooter where you play
            as a corporate space ranger, tasked with reclaiming your corporates
            colony.
          </p>
        </div>

        <img
          className={isMobile ? styles.imageMobile : styles.imageT}
          src={require(isMobile
            ? "assets/images/gameMobile.png"
            : "assets/images/gameT.png")}
          alt="game"
        />
      </div>
    </div>
  );
};
