import userOne from "../../src/assets/lottie/user1.json";
import userTwo from "../../src/assets/lottie/user2.json";
import userThree from "../../src/assets/lottie/user3.json";
import userFour from "../../src/assets/lottie/user4.json";

export const mockData = {
  users: [
    {
      id: 1,
      title: "Ahmed Refae",
      programmer: "Storyteller",
      src: require("../assets/images/heroFour.png"),
    },
    {
      id: 2,
      title: "Ahmed Naboot",
      programmer: "Programmer",
      src: require("../assets/images/heroTwo.png"),
    },
    {
      id: 3,
      title: "Tarek El-Sayed",
      programmer: "Artist",
      src: require("../assets/images/heroThree.png"),
    },
    {
      id: 4,
      title: "Ahmed El-Boukhary",
      programmer: "Builder",
      src: require("../assets/images/heroOne.png"),
    },
  ],

  socialLinks: [
    {
      id: 1,
      path: require("../assets/images/twitterOutline.png"),
      link: "https://twitter.com/senetgames",
    },
    {
      id: 2,
      path: require("../assets/images/discordOutline.png"),
      link: "https://discord.com/channels/1038498699942318312/1038498699942318314",
    },
    {
      id: 3,
      path: require("../assets/images/linkedOutline.png"),
      link: "https://www.linkedin.com/company/82159752/",
    },
  ],

  services: [
    {
      icon: require("assets/images/analog.png"),
      title: "Game Development Services",
      body: "We offer our design & development skills to others, creating concepts, building prototypes, consulting on design aspects as well as developing titles for clients from concept to gold master Release.",
    },
    {
      icon: require("assets/images/flower.png"),
      title: "Story Telling & Lore building",
      body: "We have a team who can understand, how to create rich lores, an ensemble of relatable characters, and create a road map for character and worldbuilding projects, using different story tropes.",
    },
    {
      icon: require("assets/images/shape.png"),
      title: "Art Direction & Asset Design",
      body: "We have a team of talented artists and animators with a wide range of skills in the digital arts.  We focuse on creating mood and feel through unique art design & direction.",
    },
  ],
};
