import discord from "assets/images/discord.png";

import logo from "assets/images/logo.png";
import twitter from "assets/images/twitter.png";
import { SocialLinks } from "components/socialLinks";
import { mockData } from "data/mockData";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";
import MenuIcon from "./menuIcon";
import styles from "./style.module.css";

export const NavBar = () => {
  // const isDesktop = useMediaQuery({ minWidth: 992 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 669 });

  const [isChecked, setIsChecked] = useState(false);
  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  //navbar scroll changeBackground function
  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const goToHome = () => {
    close();
    window.location.href = "#home";
  };

  const { pathname } = useLocation();
  const isHide = pathname == "/privacy";

  const close = () => {
    setIsChecked(false);
  };

  const renderLogo = () => (
    <img src={logo} alt="Brand Logo" title="senet" className={styles.logo} />
  );

  return (
    <div
      className={styles.navbar}
      style={
        isHide
          ? { display: "none" }
          : { backgroundColor: navbar ? "#06192D" : "transparent" }
      }
    >
      {renderLogo()}

      {isMobile && (
        <MenuIcon setIsChecked={setIsChecked} isChecked={isChecked} />
      )}

      <div
        className={isMobile ? styles.menuItems : ""}
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
          transform: isMobile
            ? isChecked
              ? `translate(0px,0px)`
              : `translate(150%,0px)`
            : `translate(0px,0px)`,
        }}
      >
        {isMobile && (
          <MenuIcon
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            style={{
              position: "relative",
            }}
          />
        )}

        <NavLink to="/" onClick={goToHome}>
          Home
        </NavLink>
        <a onClick={close} href="#games">
          Games
        </a>
        <a onClick={close} href="#our-services">
          Services
        </a>
        <a onClick={close} href="#our-story">
          Our story
        </a>
        <a onClick={close} href="#heros">
          Team
        </a>
        <a onClick={close} href="#contact">
          Contact us
        </a>

        <div className="row">
          <a
            onClick={close}
            href={mockData.socialLinks[0].link}
            style={{ marginInlineEnd: 20 }}
            target="_blank"
          >
            <img
              src={twitter}
              alt="Twitter"
              title="Twitter"
              className={styles.icon}
            />
          </a>
          <a
            onClick={close}
            href={mockData.socialLinks[1].link}
            target="_blank"
          >
            <img
              src={discord}
              alt="Discord"
              title="Discord"
              className={styles.icon}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
