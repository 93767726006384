import { mockData } from "data/mockData";
import React from "react";
import styles from "./style.module.css";

export const SocialLinks = (props) => {
  const { small } = props;
  return (
    <div className={styles.links}>
      {mockData.socialLinks.map((item) => {
        return (
          <a className={styles.link} href={item.link} target="_blank">
            <img
              className={small ? styles.smallIcon : styles.icon}
              alt=""
              src={item.path}
            />
          </a>
        );
      })}
    </div>
  );
};
