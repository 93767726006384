import React from "react";
import styles from "./style.module.css";
import Lottie from "react-lottie";

export const OurStory = (props) => {
  return (
    <div className={styles.container} id="our-story">
      <div className={styles.child}>
        <div className={styles.header}>our Journey</div>

        <div className={styles.row}>
          <div className={styles.description}>
            Senet Interactive is a gaming Studio based in Cairo Egypt. We are a
            team of talented individuals with diverse backgrounds, and aim to
            expand on diversity further. Always adding amazing individuals with
            great heart and talent to our team. Our concepts are demonstrated
            through immersive unique bits of gameplay, an exaggerated and
            expressive art style, as well as a contemporary narrative that
            carries the experience further. We are excited to take on daily
            challenges, and know the necessary bold decisions required to
            produce something unique on a daily basis.
          </div>

          <div className={styles.gap} />

          <img
            className={styles.image}
            src={require("assets/images/journey.png")}
            alt="journey"
          />
        </div>
      </div>
    </div>
  );
};
