import { SocialLinks } from "components/socialLinks";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import styles from "./style.module.css";

export const Footer = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 961 });

  const isTablet = useMediaQuery({ query: "(min-width: 960px)" });
  const isMobile = useMediaQuery({ minWidth: 600, maxWidth: 959 });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 599px)" });

  const goToHome = () => {
    window.location.href = "#home";
  };

  const goToPrivacy = () => {
    window.location.href = "#privacy";
  };

  return (
    <div id="footer" className={styles.container}>
      <div className={styles.firstRow}>
        <img src={require("assets/images/logo.png")} className={styles.logo} />
        <div className={styles.row}>
          <p className={styles.follow}>follow us on</p>
          <SocialLinks />
        </div>
      </div>

      <div className={styles.secondeRow}>
        <p className={styles.follow}>Copyright @2022. All rights reserved</p>

        <div className={styles.rowOne}>
          <Link className={styles.link} to="/" onClick={goToHome}>
            Home
          </Link>

          <p className={styles.dot}>.</p>

          <Link className={styles.link} to="/privacy" onClick={goToPrivacy}>
            Privacy policy
          </Link>
        </div>
      </div>
    </div>
  );
};
