import { mockData } from "data/mockData";
import { useEffect, useState } from "react";
import styles from "./style.module.css";

export const Hero = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={styles.container} id="heros">
        <div className={styles.hero}>THE PARTY</div>

        <div className={styles.content}>
          {mockData.users.map((item) => {
            return (
              <div className={styles.imgContainer}>
                <div>
                  <img className={styles.image} src={item?.src} alt="Hero" />
                </div>
                <div style={{ height: windowDimensions.height / 50 }} />
                <div className={styles.name}>{item?.title}</div>
                <div className={styles.programmer}>{item?.programmer}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
