import { mockData } from "data/mockData";
import React from "react";
import styles from "./style.module.css";
export const OurServices = (props) => {
  return (
    <div className={styles.container} id="our-services">
      <div className={styles.header}>our services</div>
      <div className={styles.content}>
        {mockData.services.map((i) => {
          return (
            <div className={styles.child}>
              <img src={i.icon} className={styles.img} />
              <div className={styles.title}>{i.title}</div>
              <div className={styles.body}>{i.body}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
