import React from "react";
import menuIcon from "assets/images/menuIcon.png";
import closeIcon from "assets/images/close.png";
import styles from "./style.module.css";

export const MenuIcon = (props) => {
  const { style, setIsChecked, isChecked } = props;

  const onChange = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <div className={styles.menuContainer} style={style}>
      <img
        src={isChecked ? closeIcon : menuIcon}
        alt="menu"
        title="menu"
        className={styles.icon}
        style={{ position: "absolute", marginTop: 0 }}
      />
      <input
        id="burgerMenu"
        className={styles.icon}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        style={{ opacity: 0, marginTop: 0 }}
      />
    </div>
  );
};

export default MenuIcon;
