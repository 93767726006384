import axios from "axios";
import { SocialLinks } from "components/socialLinks";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { BASEURL } from "utils";
import styles from "./style.module.css";

export const Contact = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const contact_senet = async (e) => {
    e.preventDefault();

    const data = {
      name,
      email,
      phone,
      message,
    };
    try {
      setLoading(true);
      const res = await axios.post(BASEURL + `/contact-us`, data);
      // console.log(res);
      toast.success("Send successfully..", { position: "bottom-center" });
      setLoading(false);
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message ?? "Something error .. ");
      setLoading(false);
    }
  };

  return (
    <div id="contact">
      <div className={styles.space} />

      <div className={styles.container}>
        <div className={styles.overlay}>
          <div className={styles.words}>
            <div className={styles.love}>Love to hear from you</div>
            <div className={styles.touch}>GET IN TOUCH</div>

            <SocialLinks />

            <form className={styles.form} onSubmit={contact_senet}>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Your full name"
                required
              />
              <input
                className={styles.input}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Your email address"
                required
              />
              <input
                required
                className={styles.input}
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Your phone number"
                minLength={10}
              />
              <input
                className={styles.input}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                placeholder="Enter your message"
                minLength={6}
                required
              />

              <button type="submit" className={styles.btn} disabled={loading}>
                {loading ? (
                  <i className="fa fa-circle-o-notch fa-spin" />
                ) : (
                  "Send"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
