import cursor from "assets/images/cursor.png";
import { useEffect, useState } from "react";
import "./styles.css";

export const AnimatedGame = () => {
  const data = [
    require("assets/images/g.png"),
    require("assets/images/a.png"),
    require("assets/images/m.png"),
    require("assets/images/e.png"),
    require("assets/images/s.png"),
    require("assets/images/exclamation.png"),
  ];
  const [images, setImages] = useState([]);
  const [isCursor, setIsCursor] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsCursor((prev) => !prev);
    }, 400);

    return () => {
      // console.log("clean interval");
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let index = 0;
    let arr = [];
    const interval = setInterval(() => {
      if (index < data.length) {
        arr.push(data[index]);
        setImages([...arr]);
        index = index + 1;
      } else {
        index = 0;
        arr = [];
        // clearInterval(interval);
      }
    }, 400);

    return () => {
      // console.log("clean interval");
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="row">
      {images.map((i) => {
        return <img src={i} className={"char-img"} />;
      })}
      <img
        src={cursor}
        className={"cursor-img"}
        style={{
          animation: isCursor ? "disappear 1s ease" : "appear 1s ease",
        }}
      />
    </div>
  );
};

export default AnimatedGame;
