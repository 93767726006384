import {
  Contact,
  Games,
  Hero,
  Home as HomeComponent,
  OurServices,
  OurStory,
} from "components";
import "./style.css";

export const Home = () => {
  return (
    <div className="App">
      <HomeComponent />
      <Games />
      <OurServices />
      <OurStory />
      <Hero />
      <Contact />
    </div>
  );
};
