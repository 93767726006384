import React from "react";
import styles from "./style.module.css";

export const Privacy = (props) => {
  return (
    <div id="privacy">
      <div className={styles.container}>
        <div className={styles.headline}>Privacy Policy</div>
      </div>

      <div className={styles.body}>
        {/* About Senet Interactive */}
        <div className={styles.section}>
          <div className={styles.title}>About Senet Interactive</div>
          <div className={styles.desc}>
            Interactive is an indie gaming Studio. Our concepts are demonstrated
            through immersive unique bits of gameplay, an exaggerated and
            expressive art style, as well as a contemporary narrative that
            carries the experience further. We are excited to take on daily
            challenges, and know the necessary bold decisions required to
            produce something unique on a daily basis.
          </div>
        </div>
        {/* Collection of Personal Information */}
        <div className={styles.section}>
          <div className={styles.title}>Collection of Personal Information</div>
          <div className={styles.desc}>
            Senet Interactive Privacy Policy applies to users who have
            information collected for online psychometric assessments or
            questionnaire. The type of Personal Information we collect depends
            on the nature of the assessment and the intended output and/or needs
            of the organization.
          </div>

          <ul className={styles.list}>
            <li>
              We may collect information regarding your personal data such as
              name, age, gender, personal photo (snapshots), email address,
              phone number, nationality, national ID, language preferences,
              educational background.
            </li>
            <li>
              We may collect information regarding your position within the
              organization such as seniority level, department, years within the
              organization, years of experience, industry, salary, and/or
              previous employment history.
            </li>
            <li>
              We may collect information regarding your peers/managers at the
              workplace such as their name and position.
            </li>
            <li>
              We may collect information regarding your geographic location and
              the branch (country) you are affiliated with.
            </li>
            <li>
              We may collect IP address (automatically collected), and server
              data.
            </li>
            <li>
              We may collect your responses to survey and assessment questions.
            </li>
            <li>
              When you are utilizing our Online Systems, we may collect login
              user credentials like your username and password, after getting
              the consent of the user.
            </li>
            <li>
              In certain cases, we may request sensitive Personal Information
              about you such as payment card numbers.
            </li>
            <li>
              We may collect any organizational/ functional related information,
              to help us in building the assessment/ questionnaire.
            </li>
          </ul>

          <div className={styles.desc}>
            Please do not provide us with any Personal Information if you do not
            agree with this Policy.
          </div>
        </div>
        {/* Ways of Personal Information Collection */}
        <div className={styles.section}>
          <div className={styles.title}>
            Ways of Personal Information Collection
          </div>
          <div className={styles.desc}>
            We collect data in the following ways:
          </div>
          <div className={styles.desc}>
            1. We may collect data directly from the user using our platform,
            when the user:
          </div>

          <ul className={styles.list}>
            <li>create a new account on our platform</li>
            <li>conduct online assessments, survey, and questioner</li>
            <li>employment fair</li>
            <li>resume builder</li>
            <li>
              in case the candidate or client submit others individual personal
              information for any purpose, the candidate acknowledge his
              responsibility and he have the permission to provide the data to
              be used in the purpose of this policy.
            </li>
            <li>Interact with one of our social media channels.</li>
            <li>apply for a job through our Talent acquisition Platform</li>
          </ul>
        </div>
        <div className={styles.point}>
          2. We may collect personnel information directly from the client.
        </div>

        {/* Personnel data */}
        <div className={styles.section}>
          <div className={styles.desc}>
            3. Personnel data may gather from third party, for example, this may
            include receiving data from:
          </div>
          <ul className={styles.list}>
            <li>
              Social media sites, such as LinkedIn, Twitter, and Facebook. The
              data we collect from those sites are under the site policy of
              collecting data
            </li>
            <li>
              Peers, family, friends, and others who may recommended your data
              for sections for us or our client
            </li>
            <li>
              Third party data collection may include also the use of online
              usage data such as company mail or personnel mail Data provided by
              Third party including the basic information to identify the
              candidate such as names, contact details, and career history that
              is available on the open sites (e.g., LinkedIn) Senet Interactive
              are not controlled or responsible for Data provided by Third
              party. The third party has the right to control the use of data
              collected. Therefore, this policy is not applicable on the privacy
              policy of third party.
            </li>
          </ul>
        </div>

        {/* How We Use the Personal Information We Collect */}
        <div className={styles.section}>
          <div className={styles.title}>
            How We Use the Personal Information We Collect
          </div>
          <div className={styles.desc}>
            Senet Interactive uses the Personal Information it collects in
            several ways:
          </div>

          <ul className={styles.list}>
            <li>Analysis and Research</li>
          </ul>
          <div className={styles.desc}>
            We may use your personal information to help in conducting
            reliability and validation analysis. A critical part of ensuring our
            assessments are valid and reliable is doing statistical analysis and
            longitudinal studies on samples that should be either diverse or
            homogeneous. Diverse samples are needed to ensure that the
            assessment is not statistically biased towards specific groups and
            that results are reliable. Homogenous samples are critical to create
            norm groups and for benchmarking purposes.
          </div>

          <ul className={styles.list}>
            <li>Whiter Papers and Publications</li>
          </ul>
          <div className={styles.desc}>
            We may use your personal information in undertaking exploratory and
            investigative research studies through which we aim to identify
            patterns and trends between specific demographic information and
            other factors; such as behavioral tendencies, personality traits,
            ability levels and aspects, drivers, etc. These findings are
            published in white papers and journal articles anonymously. Any
            published paper will refer only to larger aggregations of
            individuals and will not identify you personally or include any
            results attributable to you. We perform analytics because we have a
            legitimate beneficial business interest in doing so.
          </div>

          <ul className={styles.list}>
            <li>Product Development</li>
          </ul>
          <div className={styles.desc}>
            We may use your personal information as well for gaining deeper
            insights about the preferences and use pattern of users to create,
            develop and customize assessments that better serve their needs and
            are aligned with their profiles. Collecting personal information
            helps in understanding where and how our tools, products, and
            services are utilized and, thus, improve and enhance or develop and
            market new services and tools.
          </div>

          <ul className={styles.list}>
            <li>Marketing and Announcements</li>
          </ul>
          <div className={styles.desc}>
            Occasionally, we may use your Personal Information in marketing
            materials. Before we use your Personal Information in that way, we
            will obtain consent from you. Similarly, we may use your Personal
            Information to send you marketing materials, articles/white papers,
            and/or invitations to attend our webinars or register for our public
            trainings. Please note that if this Personal Information is obtained
            indirectly (hence, because of a consulting engagement with our
            client, or you are using our Products at the request of your
            employer), you will not receive information or marketing materials
            directly from our side as a result of the Personal Information we
            obtained from those Services. Additionally, we may also use your
            Personal Information to send you informational e-mails to
            communicate updates about our tools, products, platforms, changes to
            our terms, conditions, policies, and other administrative or
            marketing information.
          </div>

          <ul className={styles.list}>
            <li>Conducting Surveys and Consulting services</li>
          </ul>
          <div className={styles.desc}>
            We may use your personal information for conducting surveys.
            Additionally, some of the personal information collected helps in
            enhancing and developing services related to HR consultancy and
            tools/products that serve OD related interventions.
          </div>

          <ul className={styles.list}>
            <li>Our recruitment services</li>
          </ul>
          <div className={styles.desc}>
            We may use Personal Information to create a profile about you and
            identify and link professional opportunities that we think may be of
            interest to you and aligned with your educational and professional
            background. We may contact you from time to time regarding those
            opportunities.
          </div>

          <ul className={styles.list}>
            <li>Responding to your inquiries and requests</li>
          </ul>
          <div className={styles.desc}>
            We may use your Personal Information to respond to your inquiries
            and fulfill your requests when contacting us. We use your contact
            information and/or correspondence to respond to your inquiry.
          </div>

          <ul className={styles.list}>
            <li>Business and Administrative purposes</li>
          </ul>
          <div className={styles.desc}>
            We use your Personal Information for internal business and
            administrative purposes: internal communication about candidates and
            clients, measuring the effectiveness of our services and marketing
            activities, administering our services, maintaining and securing our
            infrastructure, and for financial transactions.
          </div>
        </div>

        {/*Other Information We Collect */}
        <div className={styles.section}>
          <div className={styles.title}>Other Information We Collect</div>
          <div className={styles.desc}>
            Senet Interactive collects other information that may not
            specifically disclose personal identities. This information could be
            as the following examples:
          </div>

          <ul className={styles.list}>
            <li>Technology-related information such as cookies</li>
            <li>Platforms and App utilization data</li>
            <li>
              Demographic and work-related information provided subject to user
              consent
            </li>
          </ul>

          <div className={styles.desc}>
            Any other information we collect is being collected under law
            abiding terms and conditions that are clearly written and
            communicated with our business partners, providers and
            stake-holders, and after signing mutual agreements, Non-disclosure
            Agreements (NDA).
          </div>
        </div>
      </div>
    </div>
  );
};
