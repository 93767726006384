import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Contact, Footer, NavBar, NoPage } from "./components";
import { Home } from "./pages/home";
import "./App.css";
import { Privacy } from "components/privacy";
import ScrollToTop from "routes/scrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <NavBar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<NoPage />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </div>
    </BrowserRouter>
  );
};

export default App;
