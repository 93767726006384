import { AnimatedGame } from "components";
import "./style.css";

export const Home = (props) => {
  const goToContact = () => {
    window.location.href = "#contact";
  };
  return (
    <div className="container" id="home">
      <div className="title">Senet for Game development</div>
      <div className="sub-title">let's make</div>

      <AnimatedGame />
      <div className="description">
        Our main goal is to provide an experience that our audience will enjoy.
      </div>

      <div className="neon-button" onClick={goToContact}>
        Contact us
      </div>

      {/* <div class="wrapper">
        <div class="box1">One</div>
        <div class="box2">Two</div>
        <div class="box3">Three</div>
      </div> */}
    </div>
  );
};
